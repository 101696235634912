import { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import appContext from '../../src/viewer-app-module/DataBindingAppContext'
import { AppError } from '../../src/logger'
import { AdaptedComponent } from '../inverted-dependencies/components'
import { UoUFilterConnectionConfig } from '../types'

export const userInputFilterCheckboxBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponent,
  UoUFilterConnectionConfig
> = (component, connectionConfig, context) => {
  const { errorReporting } = appContext
  const { actions } = context

  const setInitialComponentValue = () => {
    component.setValue(null, { propPath: 'checked' })
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),
    bind() {
      component.onChange(
        errorReporting(
          actions.refresh,
          AppError.withMessage('Filter input adapter onChange failed'),
        ),
      )

      setInitialComponentValue()
      return
    },

    resetUserFilter() {
      setInitialComponentValue()
    },
  }
}
